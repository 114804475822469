import React, { useState, useEffect } from "react";
import { useLocation, useParams, Link } from "react-router-dom";

const OneGenre = () => {
    // we need to get the prop passed to the component
    const location = useLocation();
    const {genreName} = location.state;

    // set the stateful variables
    const [movies, setMovies] = useState([]);

    // get the ud from the url
    let {id} = useParams();

    // useEffect to get the list of movies
    useEffect(() => {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        const requestOptions = {
            method: "GET",
            headers: headers,
        };
        fetch(`${process.env.REACT_APP_BACKEND}/movies/genres/${id}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    console.log(data.message);
                } else {
                    setMovies(data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [id]);

    // return jsx
    return ( <>
        <h2>{genreName}</h2>
        <hr />
        <div className="list-group">
            {movies.length > 0 ? (
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Movie</th>
                            <th scope="col">Release Date</th>
                            <th scope="col">Runtime</th>
                            <th scope="col">MPAA Rating</th>
                        </tr>
                    </thead>
                    <tbody>
                        {movies.map((movie) => (
                            <tr key={movie.id}>
                                <td><Link to={`/movie/${movie.id}`}>{movie.title} </Link></td>
                                <td>{movie.release_date}</td>
                                <td>{movie.runtime}</td>
                                <td>{movie.mpaa_rating}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No movies in this genre yet!</p>
            )}
        </div>
    </>)

}

export default OneGenre
