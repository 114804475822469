import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Input from "./form/Input";

const GraphQL = () => {
    // setup stateful variables
    const [movies, setMovies] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [fullList, setFullList] = useState([]);

    // perform a search
    const performSearch = () => {

        console.log('searching for ' + searchTerm);
    const payload = `
         {
            search(titleContains: "${searchTerm}") {
                id
                title
                release_date
                runtime
                mpaa_rating
            }
    }`;

    const headers = new Headers(); 
    headers.append("Content-Type", "application/graphql");

    const requestOptions = {
        method: "POST",
        headers: headers,
        body: payload
    };

    fetch(`${process.env.REACT_APP_BACKEND}/graph`, requestOptions)
        .then((response) => response.json())
        .then((response) => {
            console.log(response);
            let theList = Object.values(response.data.search);
            setMovies(theList);
        })
        .catch((error) => {
            console.log(error);
        });
    }  


    const handleChange = (event) => {
        event.preventDefault();

        console.log(event.target.value);
        let value = event.target.value || "";
        setSearchTerm(value);

        if (event.target && event.target.value !== undefined) {
            performSearch();
        } else {
            setMovies(fullList);
        }
    }

    // useEffect to get the list of movies
    useEffect(() => {
        const payload = `
            {
                list {
                    id
                    title
                    release_date
                    runtime
                    mpaa_rating    
                }
            }`;

        const headers = new Headers();
        headers.append("Content-Type", "application/graphql");
        const requestOptions = {
            method: "POST",
            headers: headers,
            body: payload
        };
        fetch(`${process.env.REACT_APP_BACKEND}/graph`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                let theList = Object.values(response.data.list);
                setMovies(theList);
                setFullList(theList);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    return (
        <div>
            <h2>GraphQL</h2>
            <hr />
            <form onSubmit={handleChange}>
                <Input
                     title={"Search"}
                    type={"search"}
                    name={"search"}
                    className={"form-control"}
                    value={searchTerm}
                    onChange={handleChange}
                />
    
            </form>
            {movies ? (
               <table className="table table-striped table-hover">
                   <thead>
                       <tr>
                           <th scope="col">Movie</th>
                           <th scope="col">Release Date</th>
                           <th scope="col">Runtime</th>
                           <th scope="col">MPAA Rating</th>
                       </tr>
                   </thead>
                   <tbody>
                       {movies.map((movie) => (
                           <tr key={movie.id}>
                               <td><Link to={`/movie/${movie.id}`}>{movie.title} </Link></td>
                               <td>{new Date(movie.release_date).toLocaleDateString()}</td>
                               <td>{movie.runtime}</td>
                               <td>{movie.mpaa_rating}</td>
                           </tr>
                       ))}
                   </tbody>

               </table>
            ) : (
                <p>No movies yet</p>
            )}
        </div>
);

  
}
export default GraphQL;