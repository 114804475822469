import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Genres = () => {
    const [genres, setGenres] = useState([]);
    const [error , setError] = useState(null);

    useEffect(() => {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        const requestOptions = {
            method: "GET",
            headers: headers,
        };
        fetch(`${process.env.REACT_APP_BACKEND}/genres`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    setError(data.message);
                } else {
                    setGenres(data);
                }
            })
            .catch((error) => {
                setError(error);
            });
    }, []);

    if (error !== null) {
        return (
            <div>
                <h2>Genres</h2>
                <hr />
                <p>Error: {error.message}</p>
            </div>
        );
    } else {
        return (
                <div>
                    <h2>Genres</h2>
                    <hr />
                    <div className="list-group">
                        {genres.map((genre) => (
                            <Link
                                key={genre.id}
                                to={`/genres/${genre.id}`}
                                className="list-group-item list-group-item-action"
                                state={{ genreName: genre.genre}}
                            >
                                {genre.genre}
                            </Link>
                        ))}
                    </div>
                </div>
        );
    }
};

export default Genres;