import React, { useState } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import Input from "./form/Input";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setJwtToken } = useOutletContext();
  const { setAlertMessage } = useOutletContext();
  const { setAlertClassName } = useOutletContext();
  const {toggleRefresh} = useOutletContext();

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    // build the request payload
    let payload = {
      email: email,
      password: password,
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify(payload),
    }

    fetch(`${process.env.REACT_APP_BACKEND}/authenticate`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log("data", data);
        if(data.error) {
          setAlertClassName("alert alert-danger");
          setAlertMessage(data.message);
        } else {
          setJwtToken(data.jwtToken);
          setAlertClassName("d-none");
          setAlertMessage("");
          toggleRefresh(true);
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setAlertClassName("alert alert-danger");
        setAlertMessage(error.message);
    })
  };

  return (
    <div className="col-md-6 offset-md-3">
      <h2>Login</h2>
      <hr />
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <Input
            name="email"
            type="email"
            title="Email address"
            className="form-control"
            autoCmplete="email-new"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            name="password"
            type="password"
            title="Password"
            className="form-control"
            autoCmplete="password-new"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <hr />
        <Input type="submit" value="Login" className="btn btn-primary" />
      </form>
    </div>
  );
};

export default Login;
