import React, { useState, useEffect } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import Input from "./form/Input";
import Select from "./form/Select";
import TextArea from "./form/TextArea";
import Checkbox from "./form/Checkbox";
import swal from "sweetalert";

const EditMovie = () => {
  const navigate = useNavigate();
  const { jwtToken } = useOutletContext();
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState([]);
  const mpaaOptions = [
    { label: "G", value: "G" },
    { label: "PG", value: "PG" },
    { label: "PG-13", value: "PG-13" },
    { label: "R", value: "R" },
    { label: "NC-17", value: "NC-17" },
    { label: "18A", value: "18A" },
  ];
  const [movie, setMovie] = useState({
    id: 0,
    title: "",
    release_date: "",
    runtime: "",
    mpaa_rating: "",
    description: "",
    genres: [],
    genres_array: [Array(13).fill(false)],
  });

  let { id } = useParams();
  if (id === undefined) {
    id = 0;
  }

  useEffect(() => {
    if (jwtToken === "") {
      navigate("/login");
      return;
    }

    if (id === 0) {
      // adding a movie
      setMovie({
        id: 0,
        title: "",
        release_date: "",
        runtime: "",
        mpaa_rating: "",
        description: "",
        genres: [],
        genres_array: [Array(13).fill(false)],
      });
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      const requestOptions = {
        method: "GET",
        headers: headers,
      };

      fetch(`${process.env.REACT_APP_BACKEND}/genres`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          const checks = [];
          data.forEach((g) => {
            checks.push({ id: g.id, genre: g.genre, checked: false });
          });

          setMovie(m => ({ 
            ...m, 
            genres: checks, 
            genres_array: []
          }));
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      // editing a movie

      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${jwtToken}`);

      const requestOptions = {
        method: "GET",
        headers: headers,
      };

      fetch(`${process.env.REACT_APP_BACKEND}/admin/movies/${id}`, requestOptions)
        .then((response) => {
            if (response.status !== 200) {
                setError("Invalid response code: " + response.status);
            }
            return response.json()
        })
        .then((data) => {
          // fix release data
          data.movie.release_date = new Date(data.movie.release_date).toISOString().split("T")[0];
          const checks = [];

          data.genres.forEach((g) => {
            if(data.movie.genres_array.indexOf(g.id) !== -1) {
              checks.push({ id: g.id, genre: g.genre, checked: true });
            } else {
              checks.push({ id: g.id, genre: g.genre, checked: false });
            }
          });

          setMovie({
            ...data.movie,
            genres: checks,
          });
        })
        .catch((error) => {
          console.log("error", error);
        });

    }
  }, [id, jwtToken, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let errors = [];
    let required = [
        {"field": movie.title, "name": "title"},
        {"field": movie.release_date, "name": "release_date"},
        {"field": movie.runtime, "name": "runtime"},
        {"field": movie.mpaa_rating, "name": "mpaa_rating"},
        {"field": movie.description, "name": "description"},
    ];

    required.forEach((obj) => {
      if (obj.field === "") {
        errors.push(obj.name);
      }
    });

    if (movie.genres_array.length === 0) {
        swal({
          icon: 'error',
          title: 'Error',
          text: 'Please select at least one genre',
          confirmButtonText: 'Ok',
        })
      errors.push("genres");

    }

    setErrors(errors);

    if (errors.length > 0) {
      return false;
    }
     // passed validation so send data
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${jwtToken}`);

    // assume we are adding new movie
    let method = "PUT";

    if (movie.id !== 0) {
      method = "PATCH";
    }
    
    const requestBody = movie;
    // we ned to convert the values in JSON for the release date (to date)
    // and for runtime to int
    requestBody.release_date = new Date(movie.release_date);
    requestBody.runtime = parseInt(movie.runtime, 10);

    const requestOptions = {
      method: method,
      headers: headers,
      body: JSON.stringify(requestBody),
      credentials: "include"
    };

    fetch(`${process.env.REACT_APP_BACKEND}/admin/movies/${movie.id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setError(data.error);
          console.log(data.error)
        } else {
            console.log("Navigating to /manage-catalogue");
            navigate("/manage-catalogue");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });

  };

  const handleChange = () => (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setMovie({ ...movie, [name]: value });
  };

  const handleCheck = (event, position) => {
    console.log("handleCheck called");
    console.log("value is", event.target.value);
    console.log("checked is", event.target.checked);
    console.log("position is", position);

    let tmpArr = movie.genres;
    tmpArr[position].checked = !tmpArr[position].checked;

    let tmpIDs = movie.genres_array;
    if (!event.target.checked) {
      tmpIDs.splice(tmpIDs.indexOf(event.target.value));
    } else {
      tmpIDs.push(parseInt(event.target.value, 10));    
    }

    setMovie({ ...movie, genres_array: tmpIDs });

  }

  const handleDelete = () => {
    swal({
        title: "Are you sure?",
        text: "Once deleted, you cannot recover this movie!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          swal("Poof! Your movie has been deleted!", {
            icon: "success",
          }).then(() => {
            let headers = new Headers();
            headers.append("Authorization", `Bearer ${jwtToken}`);
            const requestOptions = {
              method: "DELETE",
              headers: headers,
            };
            fetch(`${process.env.REACT_APP_BACKEND}/admin/movies/${movie.id}`, requestOptions)
              .then((response) => response.json())
              .then((data) => {
                if (data.error) {
                  console.log(data.error);
                } else {
                  navigate("/manage-catalogue");
                }
              })
              .catch((error) => {
                console.log("error", error);
              });
          });
        } else {
          swal("Your movie is safe!");
        }
      });
  }
  const hasError = (field) => {
    return errors.indexOf(field) !== -1;
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else {
    return (
        <div>
        <h2>Add/Edit Movie</h2>
        <hr />
        {/* <pre>{JSON.stringify(movie, null, 4)}</pre> */}
        <form action="" onSubmit={handleSubmit}>
            <input type="hidden" name="id" value={movie.id} />
            <Input
            title={"Title:"}
            className={"form-control"}
            name={"title"}
            type={"text"}
            value={movie.title}
            onChange={handleChange("title")}
            errorDiv={hasError("title") ? "text-danger" : "d-none"}
            errMsg={"Please enter a movie title"}
            />

            <Input
            title={"Release Date:"}
            className={"form-control"}
            name={"release_date"}
            type={"date"}
            value={movie.release_date}
            onChange={handleChange("release_date")}
            errorDiv={hasError("release_date") ? "text-danger" : "d-none"}
            errMsg={"Please enter a release date"}
            />

            <Input
            title={"Runtime:"}
            className={"form-control"}
            name={"runtime"}
            type={"text"}
            value={movie.runtime}
            onChange={handleChange("runtime")}
            errorDiv={hasError("runtime") ? "text-danger" : "d-none"}
            errMsg={"Please enter a runtime"}
            />

            <Select
            title={"MPAA Rating:"}
            name={"mpaa_rating"}
            value={movie.mpaa_rating}
            onChange={handleChange("mpaa_rating")}
            options={mpaaOptions}
            errorDiv={hasError("mpaa_rating") ? "text-danger" : "d-none"}
            errMsg={"Please enter a mpaa rating"}
            placeholder={"Select an mpaa rating"}
            />

            <TextArea
            title={"Description:"}
            className={"form-control"}
            name={"description"}
            value={movie.description}
            rows={3}
            onChange={handleChange("description")}
            errorDiv={hasError("description") ? "text-danger" : "d-none"}
            errMsg={"Please enter a description"}
            />
            <hr />
            <h3>Genres</h3>
            {movie.genres && movie.genres.length > 1 &&
                <>
                    {Array.from(movie.genres).map((g, index) => 
                        <Checkbox
                            title={g.genre}
                            name={"genre"}
                            key={index}
                            id={"genre-" + index}
                            onChange={(event) => handleCheck(event, index)}
                            value={g.id} 
                            checked={movie.genres[index].checked}
                        />
                    )}
                </>
            }

            <hr />
            <input type="submit" value="Save" className="btn btn-primary" />

           {movie.id > 0 && <button  className="btn btn-danger ms-3" onClick={handleDelete}>Delete Moivie</button>}

        </form>
        </div>
    );    

  }
};

export default EditMovie;
